.skeleton {
  background-size: 2000px;
  animation: transition 2s infinite linear;
  background-image: linear-gradient(
    90deg,
    rgb(250, 250, 250) 0px,
    #f3f3f3 40px,
    rgb(250, 250, 250) 80px
  );
}

@keyframes transition {
  0% {
    background-position: 0px;
  }

  40%,
  100% {
    background-position: 1000px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #ffffff;
  --black: #000000;
  --offWhite: #fafafa;
  --lightGrey: #e5e5e4;
  --veryLightGrey: #efeeee;
  --darkGrey: #212121;
  --grey: #666666;
}

@font-face {
  font-family: 'Regular';
  font-weight: normal;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: bold;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 100;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Light.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 200;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Light.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 300;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Light.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 500;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 600;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-SemiBold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 700;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Regular';
  font-weight: 800;
  src: url('https://cdn.internal.matchpint.cloud/ChakraPetch-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Condensed';
  src: url('https://cdn.internal.matchpint.cloud/PPFormulaCondensed.ttf');
  font-display: swap;
}

html {
  font-family: Regular, sans-serif;
  margin: 0;
}

.headingFont {
  @apply uppercase;
  font-family: Condensed, sans-serif;

  &_lineHeight {
    @extend .headingFont;
    line-height: 0.9;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .containerA {
    @apply container;
  }
  .hiddenA {
    @apply hidden;
  }
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

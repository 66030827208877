.header {
  @apply relative bg-cover bg-center text-white;
  &_inner {
    @apply p-6 lg:p-12;
    background: linear-gradient(
      180.86deg,
      rgba(255, 255, 255, 0.01) -172.54%,
      rgba(0, 0, 0, 0.86) 99.26%
    );
  }
}

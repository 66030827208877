@import '../../index.scss';

.buttonsWrapper {
  @apply no-scrollbar w-1 flex-1 overflow-x-scroll whitespace-nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.item {
  @apply mx-2 inline shrink-0 whitespace-nowrap text-xs font-semibold uppercase opacity-30 transition-all focus:outline-none lg:mx-3 lg:text-base;
  &_selected {
    @extend .item;
    @apply border-b-2 border-black pt-1 opacity-100;
  }
}

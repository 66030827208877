@font-face {
  font-family: 'UEFAEuro-Bold';
  src: url('https://cdn.internal.matchpint.cloud/venus/uefa/UEFAEuro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'UEFAEuro-Book';
  src: url('https://cdn.internal.matchpint.cloud/venus/uefa/UEFAEuro-Book.ttf') format('truetype');
}

.baseText {
  @apply opacity-70;
  font-family: UEFAEuro-Book, sans-serif;
}

.title {
  @apply pb-1 text-2xl md:text-3xl lg:text-4xl;
  font-family: UEFAEuro-Bold, sans-serif;
}

.subtitle {
  @extend .baseText;
  @apply text-sm md:text-xl;
}

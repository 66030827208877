.carousel {
  @apply mx-auto my-2 flex justify-start overflow-x-auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: 'smooth';
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px;
  }
  > img {
    @apply m-4 h-56 min-w-[18rem] rounded-md object-cover md:min-w-[20rem] lg:m-0 lg:mr-8 lg:h-72 lg:min-w-[24rem] xl:h-96;
    scroll-snap-align: start;
  }
  > img:last-of-type {
    @apply pr-8 md:mr-auto;
  }
}

.swiper-container {
  @apply relative rounded-t border-b border-veryLightGrey;
}

.swiper-button-next,
.swiper-button-prev {
  @apply hidden h-8 w-8 rounded-full bg-white text-black after:hidden;
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
  @apply lg:block;
}

.swiper-pagination-bullet {
  @apply bg-white;
}

.swipe-image {
  @apply h-36 w-full object-cover lg:h-52;
}

@import '../../index.scss';

.fixture {
  &_container {
    @apply grid grid-cols-1 gap-8 px-6 sm:grid-cols-2 sm:px-0 md:grid-cols-3 lg:grid-cols-4;
  }
  &_tiles {
    @apply relative flex flex-col justify-between overflow-hidden rounded rounded-br-3xl border-b-2 border-lightGrey bg-white shadow-xl;
  }
  &_team {
    @apply mx-2 flex h-14 items-center text-xs font-bold text-black md:h-16 lg:mx-4 lg:h-20 lg:text-base;
  }
  &_singleTeam {
    @extend .fixture_team;
    @apply h-28 md:h-32 lg:h-40;
  }
  &_bottom {
    @apply relative mt-4 bg-offWhite p-2 pb-4 pt-4;
  }
  &_button {
    @apply absolute -top-4 right-2 flex gap-3.5 rounded-full bg-black p-2.5 px-4 font-semibold text-white md:right-0 md:mx-5 lg:left-auto lg:right-4 lg:mx-0;
  }
  &_footer {
    @apply -mb-2 -mt-2 whitespace-nowrap text-[10px] font-semibold uppercase;
  }
}

.fixture_team + .fixture_team {
  @apply border-t border-veryLightGrey;
}
